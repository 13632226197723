<template>
    <div class="card card-custom">
        <div class="card-header flex-wrap border-0 pt-6 pb-0">
            <div class="card-title">
                <h3 class="card-label">Настройки</h3>
            </div>        
        </div>
        <form>
            <div class="card-body">
                <div class="row">
                    <label class="col-form-label col-md-2 text-right">Телефон</label>
                    <BaseInput
                        class="col-md-6"
                        type="text"
                        :error="errors.get('title')"
                        v-model="form.contacts.phone"
                    />
                </div>

                <div class="row">
                    <label class="col-form-label col-md-2 text-right">Email</label>
                    <BaseInput
                        class="col-md-6"
                        type="email"
                        :error="errors.get('title')"
                        v-model="form.contacts.email"
                    />
                </div>
                <div class="row">
                    <label class="col-form-label col-md-2 text-right">Адрес</label>
                    <BaseInput
                        class="col-md-6"
                        type="text"
                        :error="errors.get('title')"
                        v-model="form.contacts.address"
                    />
                </div>

                <div class="row">
                    <label class="col-form-label col-md-2 text-right">Cсылка вк</label>
                    <BaseInput
                        class="col-md-6"
                        type="text"
                        :error="errors.get('title')"
                        v-model="form.contacts.social.vk"
                    />
                </div>

                <div class="row">
                    <label class="col-form-label col-md-2 text-right">Cсылка fb</label>
                    <BaseInput
                        class="col-md-6"
                        type="text"
                        :error="errors.get('title')"
                        v-model="form.contacts.social.fb"
                    />
                </div>
            </div>
            <div class="card-footer">
                <BaseButton
                    :loading="isProcess"
                    :disabled="isProcess"
                    @click.prevent="save('contacts')"
                >Сохранить</BaseButton>
                <BaseButton btnClass="btn-secondary" @click.prevent="$emit('close')">Отменить</BaseButton>
            </div>
        </form>
    </div>
</template>

<script>

import { Errors } from '@/classes/Errors.js'

export default {
    props: {
        id: [Number, String]
    },

    data() {
        return {
            isProcess: false,
            errors: new Errors(),
            dialogVisible: false,
            form: this.initForm(),

        }
    },

    methods: {
        initForm() {
            return {
                contacts: {
                    address: null,
                    phone: null,
                    email: null,
                    social: {
                        vk: null,
                        fb: null
                    }
                },
            }
        },
        save(key) {

            if (this.isProcess) {
                return
            }

            this.isProcess = true

            const data = {
                key: key,
                value: this.form[key]
            }

            axios.post('/settings', data).then(() => {
                this.isProcess = false

                this.$notify({
                    type: 'success',
                    message: 'Настройки сохранены!'
                })
                this.errors.clearAll()


            }).catch(error => {
                this.isProcess = false
                console.log(error)
                if (error.response && error.response.status == 422) {
                    this.$notify({
                        type: 'warning',
                        message: error.response.data.message
                    })
                    this.errors.set(error.response.data.errors)
                }
            });

        },

        loadSettings() {
            axios.get(`/settings`).then(res => {

                const settings = (res.data)

                for (var i = 0; i <= settings.length; i++) {

                    let item = settings[i]

                    if (item && item.value) {
                        Object.assign(this.form[item.key], item.value)
                    }
                }


            }).catch(error => {
                this.$notify({
                    type: 'error',
                    message: 'Не удалось загрузить настройки'
                })
            })
        }

    },

    created() {
        this.loadSettings()
    }
}
</script>